import React from 'react';
import { motion } from 'framer-motion';
import { categories } from '../../data/services';

function CategoryToggle({ activeCategory, setActiveCategory }) {
  return (
    <div className="flex justify-center space-x-4 my-8">
      {categories.map((category) => (
        <motion.button
          key={category.id}
          className={`px-4 py-2 rounded-full ${
            activeCategory === category.id ? 'bg-primary-100 text-white' : 'bg-bg-200'
          }`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setActiveCategory(category.id)}
        >
          {category.name}
        </motion.button>
      ))}
    </div>
  );
}

export default CategoryToggle;