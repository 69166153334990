import { motion } from "framer-motion";

const solutions = [
  {
    title: "Creating Safe Spaces",
    description:
      "We've designed our centers to be welcoming, confidential, and judgment-free environments where you can openly discuss your mental and sexual health concerns.",
  },
  {
    title: "Breaking the Stigma",
    description:
      "Through education, community outreach, and compassionate care, we're working to normalize conversations about mental and sexual health.",
  },
  {
    title: "Accessible Healthcare",
    description:
      "We offer flexible scheduling, telehealth options, and a range of services to ensure that quality healthcare is accessible to everyone, regardless of their circumstances.",
  },
];

export default function Solutions() {
  return (
    <div className="bg-gradient-to-r from-green-400 to-blue-500 text-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-xl sm:rounded-2xl md:rounded-3xl">
      <motion.h2
        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-8 md:mb-10 text-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        How We're Making a Difference
      </motion.h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-5xl sm:max-w-6xl mx-auto">
        {solutions.map((solution, index) => (
          <motion.div
            key={index}
            className="bg-white bg-opacity-20 p-4 sm:p-6 md:p-8 rounded-lg"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut", delay: index * 0.2 }}
          >
            <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold mb-3 sm:mb-4 md:mb-5">{solution.title}</h3>
            <p className="text-base sm:text-lg md:text-xl">{solution.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
