import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import quizImage from '../../assets/quiz.png'; // Make sure to add this image

function QuizSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section ref={ref} className="py-20 px-4 lg:px-20 bg-bg-200 rounded-3xl">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <motion.div
          className="lg:w-1/2 mb-10 lg:mb-0"
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -50 }
          }}
          transition={{ duration: 2 }}
        >
          <img src={quizImage} alt="Mental Health Quiz" className="rounded-lg shadow-xl w-full h-auto object-cover" />
        </motion.div>
        <motion.div 
          className="lg:w-1/2 lg:pl-12"
          initial="hidden"
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 }
          }}
          transition={{ duration: 2 }}
        >
          <h2 className="text-3xl font-heading text-primary-100 mb-4">Assess Your Mental Health</h2>
          <p className="text-lg text-text-200 mb-8">
            Take our quick and confidential quiz to get an initial assessment of your mental health. 
            This can be a first step towards understanding your needs and seeking appropriate support.
          </p>
          <motion.a
            href="https://tally.so/r/wa0kBv" target="_blank" rel="noopener noreferrer"
            className="bg-accent-100 text-bg-100 font-semibold py-3 px-6 rounded-md hover:bg-opacity-90 transition-colors inline-block"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Take our Quiz
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
}

export default QuizSection;
