import React from 'react';
import { motion } from 'framer-motion';
import { testimonials } from '../../data/data';

function TestimonialsCarousel() {
  return (
    <section className="py-20 px-4 lg:px-20 bg-primary-100 text-bg-100 overflow-hidden rounded-3xl my-10">
      <h2 className="text-3xl font-heading mb-12 text-center">Testimonials</h2>
      <div className="relative w-full overflow-hidden">
        <motion.div
          className="flex"
          initial={{ x: '25%' }}
          animate={{ x: '-100%' }}
          transition={{
            repeat: Infinity,
            repeatType: 'loop',
            duration: 55, // Increased duration to make the scrolling smoother
            ease: 'linear',
          }}
        >
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div
              key={index}
              className="bg-bg-200 p-8 rounded-lg shadow-xl mx-4 min-w-[300px] max-w-[350px] flex-shrink-0 overflow-hidden"
            >
              <p className="text-text-100 text-lg mb-4 text-left break-words leading-relaxed">
                {testimonial.text}
              </p>
              <p className="text-accent-100 font-semibold text-center">- {testimonial.name}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}

export default TestimonialsCarousel;
