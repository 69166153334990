import angerManagementImage from '../assets/anger_management.jpg';
import anxietyImage from '../assets/anxiety.jpg';
import careerMotivationImage from '../assets/career_motivation.jpg';
import childRelatedImage from '../assets/child_problems.jpg';
import deaddictionImage from '../assets/deaddiction.jpg';
import dementiaImage from '../assets/dementia.jpg';
import depressionImage from '../assets/depression.jpg';
import eatingDisorderImage from '../assets/eating_disorders.jpg';
import phobiaImage from '../assets/phobias.jpg';
import ocdImage from '../assets/ocd.jpg';
import personalityDevelopmentImage from '../assets/personality_development.jpg';
import relationshipIssuesImage from '../assets/relationship_issues.jpg';
import sexProblemImage from '../assets/sex_problems.jpg';
import sleepProblemsImage from '../assets/sleep_problems.jpg';
import stressManagementImage from '../assets/stress_management.jpg';
import Behaviour from '../assets/behaviour.jpg';
import Psychotherapy from '../assets/Psychotherapy.jpg';
import Couples from '../assets/couples.jpg';
import Family from '../assets/family.jpg';
import Grief from '../assets/grief.jpg';
import Lgbtq from '../assets/stress_management.jpg';
import Parent from '../assets/parents.jpg';
import Teenage from '../assets/teenage.jpg';
import Afford from '../assets/affordable.jpg';
import Package from '../assets/home_collection.jpg';
import Report from '../assets/report.jpg';
import Sterile from '../assets/sterile.jpg';
import Tablet from '../assets/tablets.jpg';
import Injection from '../assets/injection.jpg';
import Capsule from '../assets/capsule.jpg';
import Madhuvamsi from '../assets/madhuvamsi.jpg';
import HimaBindu from '../assets/hima-bindu.jpeg';
import ChandanaShinde from '../assets/chandana-shinde.jpeg';
import DhvaniAgarwal from '../assets/dhvani-agarwal.jpeg';
import SwetaJambi from '../assets/sweta-jambi.jpeg';
import LasyaPriya from '../assets/lasya-priya.jpeg';
import Baishali from '../assets/baishali.jpg';
import VamshiKrishna from '../assets/vamshi-krishna.jpg';
import ErukullaMeghana from '../assets/erukulla-meghana.jpg';

export const services = [
    { title: "Anger Management", image: angerManagementImage },
    { title: "Anxiety", image: anxietyImage },
    { title: "Career & Motivation", image: careerMotivationImage },
    { title: "Child Related Problems", image: childRelatedImage },
    { title: "Deaddiction", image: deaddictionImage },
    { title: "Dementia & Forgetfulness", image: dementiaImage },
    { title: "Depression", image: depressionImage },
    { title: "Eating Disorders", image: eatingDisorderImage },
    { title: "Fear & Phobias", image: phobiaImage },
    { title: "Obsessions & Compulsions (OCD)", image: ocdImage },
    { title: "Personality Development", image: personalityDevelopmentImage },
    { title: "Relationship Issues", image: relationshipIssuesImage },
    { title: "Sexual Problems", image: sexProblemImage },
    { title: "Sleep Issues", image: sleepProblemsImage },
    { title: "Stress Management", image: stressManagementImage }
  ];

export const categoryData = {
    'mental-health': {
      title: 'Mental Health',
      services: services.filter(service => ['Anger Management', 'Anxiety', 'Depression', 'Obsessions & Compulsions (OCD)', 'Personality Development', 'Stress Management'].includes(service.title))
    },
    'sexual-health': {
      title: 'Sexual Health',
      services: services.filter(service => ['Sexual Problems'].includes(service.title))
    },
    'women-health': {
      title: 'Women Health',
      services: services.filter(service => ['Eating Disorders', 'Relationship Issues'].includes(service.title))
    }
  };


  export const testimonials = [
    {
      id: 1,
      name: "Sarah Wilson (he/him)",
      text: "I cannot thank my psychiatrist enough for helping me manage my anxiety. Their understanding, empathy, and guidance have been invaluable in my journey towards better mental health. They listened to my concerns and provided me with personalized treatment that has truly transformed my life. I feel more empowered and in control of my anxiety thanks to their care, and I would recommend them to anyone struggling with mental health challenges.",
    },
    {
      id: 2,
      name: "Sunaina Kashyap (she/her)",
      text: "My psychiatrist has been an essential part of my journey towards healing from depression. They provided me with a safe and non-judgmental space to explore my feelings, and their expertise was instrumental in my recovery. They worked with me to develop a plan that addressed my specific needs, and were always available to provide support when I needed it most. Thanks to their care, I feel more hopeful about my future.",
    },
    {
      id: 3,
      name: "Farhan Khan (they/them)",
      text: "I was struggling with addiction for years, and it was ruining my life. I tried to quit on my own, but I always relapsed. I finally sought help from a psychiatrist who specialized in deaddiction. With their guidance and support, I was able to overcome my addiction and regain control of my life. The psychiatrist was there for me every step of the way. I am forever grateful to my psychiatrist for their expertise and compassion.",
    },
    {
      id: 4,
      name: "Syeda Niharika (she/her)",
      text: "I struggled with OCD for years. I couldn't focus on anything else, and I was constantly overwhelmed by intrusive thoughts and compulsions. I finally sought help from a psychiatrist who specialized in OCD, and it was a life-changing decision. The psychiatrist helped me understand the condition. They provided me medication and therapy, and they were always available to answer my concerns. With their help, I was able to manage my symptoms.",
    },
  ];

  
export const counsellingServices = [
  {
    title: 'Behaviour Therapy',
    image: Behaviour,
    bulletPoints: ['Modify behaviors', 'Improve coping', 'Enhance well-being']
  },
  {
    title: 'Psychotherapy',
    image: Psychotherapy,
    bulletPoints: ['Explore thoughts', 'Process emotions', 'Develop insights']
  },
  {
    title: 'Couple Counselling',
    image: Couples,
    bulletPoints: ['Improve communication', 'Resolve conflicts', 'Strengthen bonds']
  },
  {
    title: 'Family Therapy',
    image: Family,
    bulletPoints: ['Enhance dynamics', 'Resolve issues', 'Foster understanding']
  },
  {
    title: 'Grief Counselling',
    image: Grief,
    bulletPoints: ['Process loss', 'Manage emotions', 'Find healing']
  },
  {
    title: 'LGBTQIA+ Support',
    image: Lgbtq,
    bulletPoints: ['Affirming care', 'Identity support', 'Community resources']
  },
  {
    title: 'Parenting Support',
    image: Parent,
    bulletPoints: ['Effective strategies', 'Child development', 'Family harmony']
  },
  {
    title: 'Teen Counselling',
    image: Teenage,
    bulletPoints: ['Address challenges', 'Build confidence', 'Develop skills']
  }
];

export const labServices = [
  {
    title: 'Affordable Testing',
    image: Afford,
    bulletPoints: ['Competitive pricing', 'Insurance accepted', 'Flexible payment']
  },
  {
    title: 'Home Collection',
    image: Package,
    bulletPoints: ['Convenient', 'Professional staff', 'Flexible scheduling']
  },
  {
    title: 'Comprehensive Reports',
    image: Report,
    bulletPoints: ['Detailed analysis', 'Quick turnaround', 'Expert interpretation']
  },
  {
    title: 'Sterile Procedures',
    image: Sterile,
    bulletPoints: ['High standards', 'Safe environment', 'Quality assurance']
  }
];

export const medicationServices = [
  {
    title: 'Tablet Medications',
    image: Tablet,
    bulletPoints: ['Flexibility in Dosing', 'Ease of Administration', 'Variety of Formulations', 'Rapid Onset of Action', 'Portability and Accessibility']
  },
  {
    title: 'Injectable Medications',
    image: Injection,
    bulletPoints: ['Improved Adherence', 'Stable Blood Levels', 'Reduced Relapse Rates', 'Convenience', 'Discreet Treatment']
  },
  {
    title: 'Capsule Medications',
    image: Capsule,
    bulletPoints: ['Rapid Absorption', 'Ease of Administration', 'Customizable Dosing', 'Reduced Gastric Side Effects', 'Convenience and Portability']
  }
];

export const conditions = [
  "Depression", "Bipolar Disorder", "Anxious Depression", "OCD",
  "Chronic Pain", "Parkinson's", "Epilepsy", "Migraine",
  "PTSD", "Schizophrenia", "Essential Tremor", "Tourette",
  "Dystonia", "Smoking Addiction", "Alzheimer's", "Autism",
  "TBI", "MS", "SCI", "Stroke Rehab", "Incontinence", "Sleep Disorders"
];

export const faqs = [
  {
    question: 'What types of diagnostic services do you offer? 🔍',
    answer: 'We offer a wide range of diagnostic services, including psychological assessments, neuropsychological evaluations, behavioral analysis, and various medical diagnostics to help identify and treat mental health and related conditions.'
  },
  {
    question: 'How long does the diagnostic process take? ⏱️',
    answer: 'The duration of the diagnostic process can vary depending on the complexity of the case and the type of evaluation being conducted. Typically, it can take anywhere from a few hours to several days, spread over multiple sessions.'
  },
  {
    question: 'Are your diagnostic services covered by insurance? 💳',
    answer: 'Many of our diagnostic services are covered by insurance. However, coverage can vary depending on your insurance provider and specific plan. We recommend contacting your insurance company directly or speaking with our billing department for more information.'
  },
  {
    question: 'Do I need a referral to access your diagnostic services? 📋',
    answer: 'In most cases, a referral is not required to access our diagnostic services. However, certain insurance providers may require a referral from your primary care physician. It’s best to check with your insurance provider or our intake squad to confirm.'
  },
  {
    question: 'What should I expect during my first visit? 🏥',
    answer: 'During your first visit, you’ll meet with one of our specialists who will conduct an initial assessment to understand your concerns and symptoms. This may include a discussion of your medical history, a physical examination, and the scheduling of any necessary diagnostic tests.'
  },
  {
    question: 'How do I prepare for my diagnostic appointment? 📅',
    answer: 'To prepare for your diagnostic appointment, please bring any relevant medical records, a list of current medications, and any referral forms if applicable. It’s also helpful to note down any symptoms or concerns you have so that you can discuss them with your specialist.'
  },
  {
    question: 'What happens after the diagnosis? 📝',
    answer: 'After the diagnosis, our team will work with you to develop a personalized treatment plan. This may include therapy, medication management, lifestyle changes, or a combination of these approaches. We’ll provide you with all the information you need to move forward with your treatment.'
  }
];

export const treatmentBenefits = [
  'Discuss your symptoms with our registered psychiatrists',
  'Treatment plans tailored to your goals',
  'Take sessions from anywhere, anytime',
  'Ongoing care team support',
];



export const team = [
  {
    name: 'Dr. Madhu Vamsi',
    role: 'Founder & Chief Psychiatrist MBBS, MD DNB Psychiatry, PGCAMH',
    image: Madhuvamsi,
    description: 'Dr. Vamsi is the founder of our practice with extensive experience in psychiatry.',
    socialMedia: [
      { platform: 'YouTube', url: 'https://www.youtube.com/@drmadhuvamsi' },
      { platform: 'Facebook', url: 'https://www.facebook.com/madhu.vamsi' },
      { platform: 'Instagram', url: 'https://www.instagram.com/madhu_vamsi' },
      { platform: 'LinkedIn', url: 'https://www.linkedin.com/in/drmadhuvamsi/' },
    ]
  },
  {
    name: 'A Hima Bindu',
    role: 'Chief Administrative Officer',
    image: HimaBindu,
    // description: 'A Hima Bindu oversees all administrative aspects, ensuring smooth operations.'
  },
  {
    name: 'Chandana Shinde',
    role: 'Counselling Psychologist',
    image: ChandanaShinde,
    // description: 'Chandana Shinde specializes in counseling and therapeutic techniques.'
  },
  {
    name: 'Dhvani Agarwal',
    role: 'Counsellor',
    image: DhvaniAgarwal,
    // description: 'Dhvani Agarwal provides counseling and support to individuals and families.'
  },
  {
    name: 'Dr. Sweta Jambi',
    role: 'Consultant Psychiatrist',
    image: SwetaJambi,
    // description: 'Dr. Jambi offers expert consultation and psychiatric care.'
  },
  {
    name: 'Lasya Priya',
    role: 'Trainee Doctor',
    image: LasyaPriya,
    // description: 'Lasya Priya is our Trainee Doctor'
  },
  {
    name: 'Baishali',
    role: 'Counselling Psychologist',
    image: Baishali,
    // description: 'Baishali is our Counselling Psychologist'
  },
  {
    name: 'Vamshi Krishna',
    role: 'Lab Director',
    image: VamshiKrishna,
    // description: 'Vamshi Krishna is our Lab Director'
  },
  {
    name: 'Erukulla Meghana',
    role: 'Nutrionist',
    image: ErukullaMeghana,
  },
];