import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuestionCircle } from 'react-icons/fa';
import { faqs } from '../../data/data';


function DiagnosticFAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <motion.div
        className="mb-12 bg-accent-200 p-8 rounded-lg"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.8, duration: 0.5 }}
      >
    <section className="bg-bg-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center flex items-center justify-center">
          <FaQuestionCircle className="mr-2" />
          Frequently Asked Questions
        </h2>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <motion.button
              onClick={() => setActiveIndex(activeIndex === index ? null : index)}
              className="w-full text-left p-4 bg-bg-200 rounded-lg focus:outline-none"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <h3 className="text-lg font-semibold">{faq.question}</h3>
            </motion.button>
            <AnimatePresence>
              {activeIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="p-4 bg-bg-100 rounded-b-lg"
                >
                  <p>{faq.answer}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </section>
    </motion.div>
  );
}

export default DiagnosticFAQ;