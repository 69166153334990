import React from 'react';
import { motion } from 'framer-motion';

const GiftOptions = () => {
  return (
    <section className="bg-primary-200 py-12 sm:py-24 text-center relative overflow-hidden rounded-tr-3xl rounded-bl-3xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2 }}
        viewport={{ once: true }}
        className="relative z-10 max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <GiftCard
            emoji="🎨"
            title="Personalized E-Card"
            description="Send a custom e-card along with the therapy pack."
          />
          <GiftCard
            emoji="🧩"
            title="Assessment Match"
            description="We'll help match your loved one with the right therapist."
          />
          <GiftCard
            emoji="🎁"
            title="Start Therapy"
            description="Gift a complete therapy session package."
          />
        </div>
      </motion.div>
      <motion.div
        className="absolute inset-0 bg-primary-100 opacity-10"
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{ duration: 60, repeat: Infinity, ease: "linear" }}
      />
    </section>
  );
};

const GiftCard = ({ emoji, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="p-6 sm:p-8 bg-white text-center cursor-pointer rounded-lg shadow-md"
  >
    <div className="text-4xl sm:text-6xl mb-4">{emoji}</div>
    <h2 className="text-lg sm:text-xl font-bold mb-2">{title}</h2>
    <p className="text-sm sm:text-base">{description}</p>
  </motion.div>
);

export default GiftOptions;