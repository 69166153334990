import React from 'react';
// import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
import { services } from '../../data/services';
// import { FaArrowRight } from 'react-icons/fa';
import ServiceTemplate from './ServiceTemplate';

function ServiceCards({ category }) {
  const filteredServices = services.filter(service => service.category === category);

  if (filteredServices.length === 0) {
    return (
      <div className="text-center py-16">
        <p className="text-3xl text-accent-100">👋 No services found in this category for now. Please check another one! 👋</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8">
      {filteredServices.map((service) => (
        <ServiceTemplate key={service.id} service={service} />
      ))}
    </div>
  );
}

export default ServiceCards;