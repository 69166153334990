
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const services = [
  {
    title: 'Counselling',
    description: 'Professional counselling services to support your mental health journey.',
    url: '/services/counselling'
  },
  {
    title: 'Neuromodulation',
    description: 'Advanced neuromodulation techniques to enhance brain function and well-being.',
    url: '/services/neuromodulation'
  },
  {
    title: 'Medications',
    description: 'Expert medication management for various psychological conditions.',
    url: '/services/medications'
  },
  {
    title: 'Lab',
    description: 'State-of-the-art laboratory services for comprehensive diagnostics.',
    url: '/services/lab'
  }
];

const Services = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-text-100 mb-8 mt-10">CONDITIONS WE AID</h1>
      <p className="text-lg text-text-200 mb-8">
        At Dopamine For Thought, we offer a comprehensive range of psychological services to support your mental health and well-being. Our treatment approach consists of four key steps:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <motion.div
            key={service.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-bg-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <h2 className="text-xl font-bold text-primary-100 mb-3">{service.title}</h2>
            <p className="text-text-200 mb-4">{service.description}</p>
            <Link
              to={service.url}
              className="inline-block bg-primary-100 text-white py-2 px-4 rounded hover:bg-primary-200 transition-colors duration-300"
              >
              Learn More
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
}


export default Services;