import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import DiagnosticHero from '../components/diagnostics/DiagnosticHero';
import ServiceCards from '../components/diagnostics/ServiceCards';
import AssessmentCTA from '../components/diagnostics/AssessmentCTA';
import PrivacyNote from '../components/diagnostics/PrivacyNote';
import DiagnosisComponent from '../components/diagnostics/DiagnosisComponent';
import DiagnosticFAQ from '../components/diagnostics/DiagnosticFAQ';
import CategoryToggle from '../components/diagnostics/CategoryToggle';
import { categories } from '../data/services';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5
};

function Diagnostic() {
  const [activeCategory, setActiveCategory] = useState(categories[0].id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className='w-full m-0 bg-bg-100'
    >
      <DiagnosticHero />
      <div className="my-16">
        <DiagnosisComponent />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="my-16"
      >
        <CategoryToggle activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
      </motion.div>
      <AnimatePresence mode='wait'>
        <motion.div
          key={activeCategory}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="mb-16"
        >
          <ServiceCards category={activeCategory} />
        </motion.div>
      </AnimatePresence>
      <AssessmentCTA />
      <div className="my-16">
        <PrivacyNote />
      </div>
      
      <div className="my-16">
        <DiagnosticFAQ />
      </div>
    </motion.div>
  );
}

export default Diagnostic;