import React from 'react';
import { motion } from 'framer-motion';
import { FaVirus, FaBrain, FaSadTear, FaFistRaised, FaMoon, FaHandsHelping, FaUserInjured, FaBookReader } from 'react-icons/fa';

const diagnosisItems = [
  { icon: FaVirus, text: 'COVID 19 concerns' },
  { icon: FaBrain, text: 'Anxiety' },
  { icon: FaSadTear, text: 'Depression' },
  { icon: FaFistRaised, text: 'Motivation' },
  { icon: FaMoon, text: 'Sleep troubles' },
  { icon: FaHandsHelping, text: 'Relationships' },
  { icon: FaUserInjured, text: 'Recent trauma or loss' },
  { icon: FaBookReader, text: 'Lack of concentration' },
];

function DiagnosisComponent() {
  return (
    <section className="bg-[#f5f1ed] py-16 rounded-3xl">
      <div className="container mx-auto px-16">
        <h2 className="text-3xl font-bold text-center mb-12">For all your mental health concerns</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {diagnosisItems.map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="bg-white rounded-full p-6 mb-4 shadow-md">
                <item.icon className="text-4xl text-primary-100" />
              </div>
              <span className="text-center text-sm">{item.text}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default DiagnosisComponent;