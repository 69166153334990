import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import BusinessIntro from './BusinessIntro';
import Treatments from './Treatments';
import BusinessServices from './BusinessServices';
import CallToAction from './CallToAction';
import BrochureDownload from './BrochureDownload';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

function AnimatedSection({ children, className }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={itemVariants}
      className={className}
    >
      {children}
    </motion.div>
  );
}

function Wellness({services, treatments, name}) {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-24 w-full"
    >
      <AnimatedSection>
        <BusinessIntro name={name}/>
      </AnimatedSection>

      <AnimatedSection>
        <Treatments treatments={treatments} />
      </AnimatedSection>

      <AnimatedSection>
        <BusinessServices businessServices={services} />
      </AnimatedSection>

      <AnimatedSection className="w-full mx-auto">
        <CallToAction />
      </AnimatedSection>

      <AnimatedSection className="w-full mx-auto">
        <BrochureDownload name={name} />
      </AnimatedSection>
    </motion.div>
  );
}

export default Wellness;