import React from 'react';
import logo from '../assets/logo.png';

function Loader() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-bg-100 z-50">
      <img src={logo} alt="Dopamine For Thought Logo" className="w-32 h-32 animate-pulse" />
      <h2 className="mt-4 text-2xl font-heading text-primary-100 animate-pulse">Dopamine For Thought</h2>
    </div>
  );
}

export default Loader;