import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaArrowUp, FaWhatsapp } from 'react-icons/fa';
import { MdMoreHoriz } from "react-icons/md";
import { IoMdClose } from 'react-icons/io';
import Contact from './Team/Contact';  // Import the Contact component

const Tooltips = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSocialButtons, setShowSocialButtons] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleSocialButtons = () => {
    setShowSocialButtons(!showSocialButtons);
  };

  const socialMedia = [
    { icon: FaFacebook, label: 'Facebook', href: 'https://www.facebook.com/madhu.vamsi/' },
    { icon: FaInstagram, label: 'Instagram', href: 'https://www.instagram.com/MADHU_VAMSI' },
    { icon: FaLinkedin, label: 'LinkedIn', href: 'https://www.linkedin.com/company/dopamineforthought/' },
    { icon: FaYoutube, label: 'YouTube', href: 'https://www.youtube.com/@drmadhuvamsi' },
  ];

  return (
    <>
      {/* Repositioned Book Appointment Button */}
      <motion.div
        initial={{ opacity: 0, x: '50%' }}
        animate={{ opacity: 1, x: isHovered ? 0 : '50%' }}
        transition={{ duration: 0.5 }}
        className="fixed top-1/2 right-0 z-50 transform -translate-y-1/2"
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        <motion.button
          className="px-4 py-2 bg-primary-100 text-white font-heading rounded-full hover:bg-primary-200 transition-colors"
          animate={{ x: ['0%', '-10%', '0%'], transition: { duration: 3, repeat: Infinity, repeatType: 'loop' } }}
          onClick={togglePopup}
        >
          Book Appointment
        </motion.button>
      </motion.div>

      {/* Popup for Contact Component */}
      <AnimatePresence>
        {showPopup && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="bg-transparent rounded-lg overflow-hidden shadow-xl w-full max-w-[90vw] sm:max-w-[80vw] md:max-w-[70vw] lg:max-w-[60vw] xl:max-w-[50vw] relative"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
            >
              <button
                className="absolute top-2 right-2 z-10 text-red-500 hover:text-red-700 transition-colors bg-white bg-opacity-50 rounded-full p-1"
                onClick={togglePopup}
                aria-label="Close popup"
              >
                <IoMdClose className="w-6 h-6 sm:w-8 sm:h-8" />
              </button>
              <div className="p-4 overflow-y-auto max-h-[90vh] scrollbar-hide">
                <Contact />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Social Media Button and Icons */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed left-4 bottom-4 z-50"
      >
        <button
          onClick={toggleSocialButtons}
          className="p-2 bg-primary-100 text-white rounded-full hover:bg-primary-200 transition-colors"
          aria-label="Toggle social media icons"
        >
          <MdMoreHoriz className="w-6 h-6" />
        </button>
        
        {showSocialButtons && (
          <div className="absolute left-0 bottom-14 space-y-2">
            {socialMedia.map(({ icon: Icon, label, href }) => (
              <motion.div
                key={label}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="relative group"
              >
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block p-2 bg-primary-100 text-white rounded-full hover:bg-primary-200 transition-colors"
                  aria-label={`Visit our ${label} page`}
                >
                  <Icon className="w-6 h-6" />
                </a>
                <span className="absolute left-14 top-1/2 transform -translate-y-1/2 w-24 p-2 bg-text-100 text-white text-xs font-sans text-center rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {label}
                </span>
              </motion.div>
            ))}
          </div>
        )}
      </motion.div>

      {/* WhatsApp Button */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed bottom-4 right-4 z-50"
      >
        <div className="relative group">
          <a
            href="https://wa.me/919700168944"  // Replace with your WhatsApp number
            target="_blank"
            rel="noopener noreferrer"
            className="block p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors"
            aria-label="Contact us on WhatsApp"
          >
            <FaWhatsapp className="w-6 h-6" />
          </a>
          <span className="absolute -top-10 right-0 w-24 p-2 bg-text-100 text-white text-xs font-sans text-center rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            WhatsApp Us
          </span>
        </div>
      </motion.div>

      {/* Go to Top Button */}
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed bottom-20 right-4 z-50"
        >
          <div className="relative group">
            <button
              onClick={scrollToTop}
              className="p-2 bg-primary-100 text-white rounded-full hover:bg-primary-200 transition-colors"
              aria-label="Scroll to top of page"
            >
              <FaArrowUp className="w-6 h-6" />
            </button>
            <span className="absolute -top-10 right-0 w-24 p-2 bg-text-100 text-white text-xs font-sans text-center rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              Go to top
            </span>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Tooltips;