import React from 'react';
import { motion, useTransform } from 'framer-motion';

export default function AnimatedSection({ children, progress, index, totalSections, initiallyVisible }) {
  const sectionProgress = useTransform(
    progress,
    [(index - 0.5) / totalSections, index / totalSections, (index + 1) / totalSections],
    [0, 0.5, 1]
  );

  const opacity = useTransform(sectionProgress, [0, 0.3, 0.7, 1], [0, 1, 1, 0]);
  const y = useTransform(sectionProgress, [0, 0.1, 0.5, 1], ['100%', '0%', '0%', '-100%']);
  const scale = useTransform(sectionProgress, [0, 0.3, 0.7, 1], [0.8, 1, 1, 0.8]);

  return (
    <motion.div
      style={{ 
        opacity: initiallyVisible ? 1 : opacity, 
        y: initiallyVisible ? 0 : y, 
        scale: initiallyVisible ? 1 : scale 
      }}
      className="min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8"
    >
      {children}
    </motion.div>
  );
}
