import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { services } from '../../data/data';

function ServicesCarousel() {
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // const totalVisibleCards = 4; // Number of cards visible at once

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    const interval = setInterval(() => {
      scroll('right');
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  const handleCardClick = () => {
    navigate('/diagnostics');
  };

  const scroll = (direction) => {
    if (carouselRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = carouselRef.current;
      let scrollTo;

      if (direction === 'left') {
        scrollTo = scrollLeft - clientWidth;
        if (scrollTo < 0) scrollTo = scrollWidth - clientWidth;
      } else {
        scrollTo = scrollLeft + clientWidth;
        if (scrollTo >= scrollWidth) scrollTo = 0;
      }

      carouselRef.current.scrollTo({
        left: scrollTo,
        behavior: 'smooth'
      });

      // setCurrentIndex(Math.floor(scrollTo / (clientWidth / totalVisibleCards)));
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-10 px-4 lg:px-20 relative"
    >
      <h2 className="text-2xl md:text-3xl font-heading text-primary-100 mb-8 md:mb-12 text-center">CONDITIONS WE AID</h2>
      
      <div className="flex items-center">
        <button
          onClick={() => scroll('left')}
          className="p-2 bg-bg-300 rounded-full mr-2 md:mr-4 focus:outline-none text-primary-100 text-xl md:text-2xl z-10"
          aria-label="Scroll left"
        >
          &lsaquo;
        </button>

        <div 
          ref={carouselRef}
          className="flex overflow-x-auto scrollbar-hide space-x-4 pb-4 snap-x snap-mandatory"
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="w-64 md:w-72 flex-shrink-0 snap-center"
            >
              <motion.div
                className="bg-bg-200 rounded-lg shadow-lg h-full flex flex-col cursor-pointer overflow-hidden"
                onClick={handleCardClick}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <div className="h-48 md:h-56 relative">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-end">
                    <h3 className="text-lg md:text-xl font-semibold text-white p-4 w-full text-center bg-black bg-opacity-50">
                      {service.title}
                    </h3>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>

        <button
          onClick={() => scroll('right')}
          className="p-2 bg-bg-300 rounded-full ml-2 md:ml-4 focus:outline-none text-primary-100 text-xl md:text-2xl z-10"
          aria-label="Scroll right"
        >
          &rsaquo;
        </button>
      </div>

      {/* <div className="flex justify-center mt-4">
        {[...Array(Math.ceil(services.length / totalVisibleCards))].map((_, index) => (
          <motion.div
            key={index}
            className={`w-2 h-2 rounded-full mx-1 ${index === currentIndex ? 'bg-primary-100' : 'bg-bg-300'}`}
            whileHover={{ scale: 1.2 }}
          />
        ))}
      </div> */}
    </motion.section>
  );
}

export default ServicesCarousel;