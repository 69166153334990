import { motion } from "framer-motion";

export default function Introduction() {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white w-full md:w-2/3 p-6 sm:p-8 md:p-10 rounded-xl sm:rounded-2xl md:rounded-3xl">
      <motion.div
        className="max-w-xl sm:max-w-2xl md:max-w-3xl mx-auto text-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      >
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold font-heading mb-4 sm:mb-5 md:mb-6 text-white">
          It's okay to speak about how you feel mentally and sexually
        </h1>
        <motion.p
          className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-sans text-gray-200"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.5 }}
        >
          We're here to provide a safe space for open conversations and comprehensive care.
        </motion.p>
      </motion.div>
    </div>
  );
}
