import React from 'react';
import { FaLock, FaUserMd, FaUserSecret, FaUsers, FaShieldAlt, FaClipboardCheck, FaLaptopMedical, FaUserShield } from 'react-icons/fa';
import { motion } from 'framer-motion';

function PrivacyNote() {
  
  const privacyFeatures = [
    { icon: FaUserMd, text: 'Registered psychiatrists and therapists' },
    { icon: FaUserSecret, text: 'Completely confidential' },
    { icon: FaUsers, text: 'For everyone - Men, women, LGBTQ+' },
    { icon: FaLock, text: 'Data encryption for your protection' },
    { icon: FaShieldAlt, text: 'Secure and private online consultations' },
    { icon: FaClipboardCheck, text: 'HIPAA-compliant record keeping' },
    { icon: FaLaptopMedical, text: 'Telehealth services available' },
    { icon: FaUserShield, text: 'Strict privacy policy adherence' }
  ];
  

  return (
    <section className="bg-bg-200 py-16 my-24 rounded-3xl">
      <div className="container mx-auto px-16">
        <motion.div 
          className="flex flex-col items-center justify-center mb-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaLock className="text-primary-100 text-4xl mb-4" />
          <h3 className="text-3xl font-bold text-center">We Value Your Privacy</h3>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-8">
          {privacyFeatures.map((feature, index) => (
            <motion.div 
              key={index} 
              className="flex flex-col items-center text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <feature.icon className="text-primary-100 text-5xl mb-2 mt-5" />
              <p className="text-sm w-6/12">{feature.text}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PrivacyNote;