import React from 'react';
import { motion } from 'framer-motion';

const treatmentVariants = {
  initial: { scale: 1 },
  hover: { 
    scale: 1.05, 
    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }
};

const imageVariants = {
  hover: { 
    scale: 1.1,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }
};

function Treatments({ variants, treatments }) {
  return (
    <motion.section variants={variants}>
      <h3 className="text-2xl font-semibold mb-6">Our Treatments</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
        {treatments.map((treatment, index) => (
          <motion.div
            key={index}
            className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer relative"
            variants={treatmentVariants}
            initial="initial"
            whileHover="hover"
          >
            <motion.div className="overflow-hidden">
              <motion.img
                src={treatment.image}
                alt={treatment.name}
                className="w-full h-48 object-cover"
                variants={imageVariants}
              />
            </motion.div>
            <div className="p-4">
              <h4 className="font-semibold text-lg mb-2">{treatment.name}</h4>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileHover={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {treatment.description}
              </motion.p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
}

export default Treatments;