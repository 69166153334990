import React, { useState, useEffect} from 'react';
import { motion } from 'framer-motion';

const TemplateService = ({ title, image, bulletPoints }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="px-10">
    <motion.div 
      className="relative h-64 md:h-96 bg-primary-100 overflow-hidden rounded-lg shadow-lg p-4 flex flex-col justify-center"
      style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      whileHover={{ scale: 1.05, boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)" }}  // Hover effect on the entire card
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Title Centered, Moves Up on Click */}
      <motion.h2 
        className="text-3xl font-bold text-center text-white relative cursor-default z-10"
        initial={{ y: 0 }}
        animate={{ y: isExpanded ? -30 : 0 }}  // Move up when expanded
        transition={{ type: "spring", stiffness: 120 }}
        whileHover={{ scale: 1.1 }}  // Hover effect on title
      >
        {title}
      </motion.h2>

      {/* Bullet Points */}
      {isExpanded && (
        <motion.ul
          className="mt-4 text-white text-lg relative z-10 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          {bulletPoints.map((point, index) => (
            <motion.li 
              key={index}
              className="mb-2 flex items-center justify-center"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 * index }}
              whileHover={{ scale: 1.1, x: 5 }}  // Hover effect on bullet points
            >
              {/* Bullet Tag */}
              <span className="mr-2">•</span>
              {point}
            </motion.li>
          ))}
        </motion.ul>
      )}

      {/* Arrow Button */}
      <motion.button
        className="absolute bottom-4 right-4 bg-white p-2 rounded-full shadow-lg z-10"
        onClick={() => setIsExpanded(!isExpanded)}
        whileHover={{ scale: 1.2, rotate: 360 }}  // Hover effect on arrow button
        transition={{ duration: 0.5 }}
      >
        <motion.svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-6 w-6 text-black" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </motion.svg>
      </motion.button>
    </motion.div>
    </div>
  );
};

export default TemplateService;
