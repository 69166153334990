import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { services, helpItems } from '../../data/services';
// import {FaBrain, FaHeartbeat, FaMoon, FaUsers } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import DiagnosticFAQ from './DiagnosticFAQ';
import PrivacyNote from './PrivacyNote';
import { treatmentBenefits } from '../../data/data';




function ServicePage() {
  const { id } = useParams();
  const service = services.find(s => s.id === id);

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!service) {
    return <Navigate to="/404NotFound" />; // or return a 404 component directly
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-24 font-sans"
    >
      <div className="flex flex-col md:flex-row mb-12 bg-bg-100 rounded-lg shadow-md overflow-hidden">
        <motion.div 
          className="md:w-1/2"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <img 
            src={service.image} 
            alt={service.title} 
            className="w-full h-full object-cover"
          />
        </motion.div>

        <motion.div 
          className="md:w-1/2 p-8"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold mb-3 font-heading text-text-100">{service.title}</h1>
          <p className="text-lg mb-6 text-text-200">{service.description}</p>
          <a href="https://tally.so/r/wa0kBv" target="_blank" rel="noopener noreferrer">
          <motion.button
            className="bg-primary-100 text-white px-6 py-3 rounded-full hover:bg-primary-200 transition duration-300 mb-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Book a Consultation
          </motion.button>
          </a>
          <div className="text-sm text-text-200 bg-primary-300 p-4 my-16 rounded-lg">
            <h3 className="font-bold mb-2 text-text-100 underline">Who is a psychiatrist?</h3>
            <p>A psychiatrist is a medical doctor who specializes in mental health. They are trained to diagnose, treat, and prevent mental, emotional, and behavioral disorders.</p>
          </div>
        </motion.div>
      </div>

      <motion.div 
        className="mb-12 bg-bg-200 p-8 rounded-lg"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-3 font-heading text-text-100">Online Psychiatry</h2>
        <p className="text-xl mb-4 text-accent-100">Feeling better is possible.</p>
        <p className="text-base mb-6 text-text-200">
          Our psychiatrists will work with you to understand your symptoms, review your medical and psychiatric history and if appropriate, develop a treatment plan that's right for you.
        </p>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {treatmentBenefits.map((benefit, index) => (
            <li key={index} className="flex items-center text-left text-sm">
              <FaCheck className="text-primary-100 mr-2" />
              <span>{benefit}</span>
            </li>
          ))}
        </ul>
      </motion.div>

      <motion.div
        className="mb-12 bg-bg-100 p-8 rounded-lg"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        >
        <h2 className="text-2xl font-bold mb-6 font-heading text-text-100">Our psychiatrists can help you with:</h2>
        <motion.ul 
            className="grid grid-cols-2 md:grid-cols-4 gap-6"
            variants={{
            hidden: { opacity: 0 },
            show: {
                opacity: 1,
                transition: {
                staggerChildren: 0.1
                }
            }
            }}
            initial="hidden"
            animate="show"
        >
            {helpItems.map((item, index) => (
            <motion.li 
                key={index} 
                className="flex flex-col items-center text-center cursor-pointer" // Added cursor-pointer for the arrow or hand cursor
                variants={{
                hidden: { y: 20, opacity: 0 },
                show: { y: 0, opacity: 1 }
                }}
                whileHover={{ 
                scale: 1.1,  // Increased scale for a more dramatic effect
                rotate: 5,   // Added a slight rotation to the icon on hover
                transition: {
                    duration: 0.3,  // Made the transition quicker
                    ease: "easeInOut" 
                }
                }}
            >
                <div className="text-white p-4 rounded-full mb-2 text-6xl">
                {item.icon}
                </div>
                <span className="text-sm text-text-200">{item.label}</span>
            </motion.li>
            ))}
        </motion.ul>
        </motion.div>



        <PrivacyNote />
        <DiagnosticFAQ />

      {/* <motion.div
        className="bg-primary-300 p-8 rounded-lg"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      > */}
        
      {/* </motion.div> */}
    </motion.div>
  );
}

export default ServicePage;