import React, {useEffect} from 'react';
// import { motion } from 'framer-motion';
import HeroSection from '../components/home/HeroSection';
import ServicesCarousel from '../components/home/ServicesCarousel';
import QuizSection from '../components/home/QuizSection';
import TestimonialsCarousel from '../components/home/TestimonialsCarousel';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full m-0'>
      <HeroSection />
      <ServicesCarousel />
      <QuizSection />
      <TestimonialsCarousel />
    </div>



  );
}

export default Home;