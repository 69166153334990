import React from 'react';
import { motion } from 'framer-motion';
import NeuroImage from '../../assets/neuromodulation.jpg';
import HeroImage from '../../assets/neuromodulation-hero.jpg';  // Add your hero image
import GiftContact from '../giftAFriend/GiftContact';
import PrivacyNote from '../diagnostics/PrivacyNote';
import { conditions } from '../../data/data';


const Neuromodulation = () => {
  return (
    <div className="mx-auto px-10">
      {/* Hero Section */}
      <div className="relative w-full h-64 md:h-80 lg:h-96 bg-primary-100 overflow-hidden rounded-2xl md:rounded-3xl mb-8 md:mb-12">
        <motion.img
          src={HeroImage}
          alt="Neuromodulation Hero"
          className="w-full h-full object-cover"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-10 md:p-10">
          <motion.h1
            className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white text-center"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            Neuromodulation
          </motion.h1>
        </div>
      </div>


      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="mb-12"
      >
        <h2 className="text-2xl font-bold text-primary-100 mb-4">What is Neuromodulation?</h2>
        <p className="text-lg text-text-200 mb-4">
          Also known as NON INVASIVE BRAIN STIMULATION (NIBS), neuromodulation offers:
        </p>
        <ul className="list-disc list-inside text-text-200 mb-4">
          <li>FASTER recovery when used as an adjuvant</li>
          <li>Hardly any known side effects, no anaesthesia</li>
          <li>Safe during pregnancy and breastfeeding</li>
          <li>Suitable for ages 6 to 99 years</li>
        </ul>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mb-12"
      >
        <h2 className="text-2xl font-bold text-primary-100 mb-4">Evidence Based</h2>
        <img src={NeuroImage} alt="Neuromodulation" className="w-full h-auto rounded-lg shadow-lg mb-4" />
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <h2 className="text-2xl font-bold text-primary-100 mb-4">Neuromodulation is Useful For:</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {conditions.map((condition, index) => (
            <motion.div
              key={index}
              className="bg-bg-200 p-4 rounded-lg shadow-md"
              whileHover={{ scale: 1.05, backgroundColor: "#c1ffff" }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <motion.p
                className="text-text-100 text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {condition}
              </motion.p>
            </motion.div>
          ))}
        </div>
      </motion.div>
      
    <GiftContact />
    <PrivacyNote />
    </div>
  );
};

export default Neuromodulation;