import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import { db } from '../../firebase';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

const MediaItem = React.memo(({ item }) => (
  <motion.div 
    className="bg-white rounded-lg shadow-md overflow-hidden"
    whileHover={{ scale: 1.05 }}
    transition={{ duration: 0.3 }}
  >
    <img src={item.image_url} alt={item.title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h3 className="text-lg font-semibold text-text-100 mb-2">{item.title}</h3>
      <p className="text-sm text-text-200 mb-2">{item.source}</p>
      <motion.a 
        href={item.link} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-primary-100 hover:text-primary-200 text-sm inline-block"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        Read More →
      </motion.a>
    </div>
  </motion.div>
));

const MediaCoverage = () => {
  const [coverageItems, setCoverageItems] = useState([]);

  const fetchMediaCoverage = useCallback(async () => {
    const q = query(collection(db, 'media_coverage'), orderBy('timestamp', 'desc'), limit(10));
    const querySnapshot = await getDocs(q);
    const items = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCoverageItems(items);
  }, []);

  useEffect(() => {
    fetchMediaCoverage();
  }, [fetchMediaCoverage]);

  const memoizedItems = useMemo(() => coverageItems, [coverageItems]);

  return (
    <section className="py-12">
      <h2 className="text-3xl font-heading font-semibold text-text-100 mb-6">IN THE NEWS</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {memoizedItems.map((item, index) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <MediaItem item={item} />
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default MediaCoverage;