import React, { useState, useEffect} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BusinessHero from '../components/business/BusinessHero';
import Wellness from '../components/business/Wellness';
import { studentServices, studentTreatments, workplaceServices, workplacetreatments } from '../data/services'


const categories = [
  { id: 'workplace', name: 'Workplace Wellness' },
  { id: 'student', name: 'Student Wellness' },
];

function Business() {
  const [activeCategory, setActiveCategory] = useState('workplace');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BusinessHero />
      
      <div className="max-w-4xl mx-auto mt-12">
        <div className="flex justify-center space-x-4 my-8">
          {categories.map((category) => (
            <motion.button
              key={category.id}
              className={`px-4 py-2 rounded-full ${
                activeCategory === category.id ? 'bg-primary-100 text-white' : 'bg-bg-200'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveCategory(category.id)}
            >
              {category.name}
            </motion.button>
          ))}
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeCategory === 'workplace' ? (
              <Wellness services={workplaceServices} treatments={workplacetreatments} name={'Workplace Wellness'}/>
            ) : (
              <Wellness services={studentServices} treatments={studentTreatments} name={'Student Wellness'}/>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Business;