import { motion } from "framer-motion";

const questions = [
  "🛑 Why isn't there a safe space for people to access mental or sexual healthcare?",
  "🧠 How can we break the stigma surrounding these important health issues?",
  "🏥 What if healthcare was more accessible, friendly, and judgment-free?"
];

export default function Questions() {
  return (
    <div className="bg-gradient-to-r from-yellow-400 via-orange-400 to-pink-500 text-white p-6 sm:p-8 md:p-10 rounded-xl sm:rounded-2xl md:rounded-3xl">
      {questions.map((question, index) => (
        <motion.div
          key={index}
          className="flex flex-col items-center mb-6 sm:mb-8 w-full md:w-2/3 mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: index * 0.3 }}
        >
          <div className="w-full text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-white">
              {question}
            </h2>
          </div>
        </motion.div>
      ))}
    </div>
  );
}
