import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function GiftContact() {
  const message = "Hello%20doctor,%20I%20came%20across%20your%20website%20and%20would%20like%20to%20know%20more%20about%20the%20Gift-a-Friend%20scheme%20on%20your%20website.";
  const phoneNumber = "+919700168944"; // Replace with your full phone number in international format
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;


  return (
    <section className="py-12 mt-32 text-center">
      <AnimatePresence mode='wait'>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="relative z-10 max-w-lg sm:max-w-xl md:max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 bg-white rounded-lg shadow-lg mb-12 sm:mb-16"
        >
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-text-100 mb-4">
            Have more questions?
          </h2>
          <p className="text-base sm:text-lg md:text-xl text-text-200 mb-6">
            Reach out to our care team on WhatsApp. We’re here to help you find the perfect gift therapy option for your loved one.
          </p>
          <a 
            href={whatsappLink} 
            target="_blank" 
            rel="noopener noreferrer"
            className="bg-accent-100 text-white py-2 px-4 sm:py-3 sm:px-6 lg:py-4 lg:px-8 rounded-full shadow-lg text-base sm:text-lg lg:text-xl font-bold transition-transform transform hover:scale-105"
          >
            WhatsApp our care Squad
          </a>
        </motion.div>

      </AnimatePresence>
    </section>
  );
}
 
export default GiftContact;
