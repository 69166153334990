import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PieChart } from 'react-minimal-pie-chart';

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 }
};

const pieChartColors = [
  "#FF6B6B", "#4ECDC4", "#45B7D1", "#FFA07A", "#98D8C8",
  "#F7DC6F", "#BB8FCE", "#82E0AA", "#F1948A", "#85C1E9"
];

function BusinessServices({ variants, businessServices }) {
  const [hoveredService, setHoveredService] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const updatedBusinessServices = businessServices.map((service, index) => ({
    ...service,
    color: pieChartColors[index % pieChartColors.length]
  }));

  return (
    <motion.section 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="p-8 rounded-lg text-center"
      style={{
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      }}
    >
      <motion.h3 variants={itemVariants} className="text-3xl font-semibold mb-8">CONDITIONS WE AID</motion.h3>
      <div className="flex flex-col items-center">
        <motion.div variants={itemVariants} className="w-full max-w-md relative mb-8">
          <PieChart
            data={updatedBusinessServices}
            labelPosition={75}
            labelStyle={{
              fill: '#fff',
              fontSize: '6px',
              fontFamily: 'sans-serif',
            }}
            label={({ dataEntry }) => dataEntry.emoji}
            animate
            animationDuration={500}
            animationEasing="ease-out"
            onMouseOver={(_, index) => setHoveredService(index)}
            onMouseOut={() => setHoveredService(null)}
            onClick={(_, index) => setSelectedService(index === selectedService ? null : index)}
            segmentsStyle={{ transition: 'stroke-width 0.3s' }}
            segmentsShift={(index) => (index === hoveredService ? 7 : 0)}
          />
          <AnimatePresence>
            {hoveredService !== null && (
              <motion.div
                className="absolute inset-0 flex justify-center items-center pointer-events-none"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="bg-white bg-opacity-80 px-4 py-2 rounded-full">
                  <span className="text-lg font-semibold" style={{ color: updatedBusinessServices[hoveredService].color }}>
                    {updatedBusinessServices[hoveredService].title}
                  </span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <motion.div variants={itemVariants} className="w-full">
          <ul className="grid grid-cols-2 gap-4">
            {updatedBusinessServices.map((service, index) => (
              <motion.li
                key={index}
                className="flex items-center justify-center cursor-pointer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedService(index === selectedService ? null : index)}
              >
                <span className="mr-2 text-3xl">{service.emoji}</span>
                <span className='text-2xl' style={{ color: service.color }}>{service.title}</span>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </div>
      {/* <AnimatePresence>
        {selectedService !== null && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mt-8 p-4 bg-white bg-opacity-80 rounded-lg max-w-md mx-auto"
          >
            <h4 className="text-xl font-semibold mb-2" style={{ color: updatedBusinessServices[selectedService].color }}>
              {updatedBusinessServices[selectedService].title}
            </h4>
            <p>{updatedBusinessServices[selectedService].description}</p>
          </motion.div>
        )}
      </AnimatePresence> */}
    </motion.section>
  );
}

export default BusinessServices;