import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import GiftAFriendHero from '../components/giftAFriend/GiftAFriendHero';
// import GiftPacks from '../components/giftAFriend/GiftPacks';
import GiftOptions from '../components/giftAFriend/GiftOptions';
import GiftContact from '../components/giftAFriend/GiftContact';

const GiftAFriend = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mb-36"
      >
        <GiftAFriendHero />
      </motion.div>

      {/* <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="mb-36"
      >
        <GiftPacks />
      </motion.div> */}

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <GiftOptions />
      </motion.div>

      <AnimatePresence mode='wait'>
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="mt-16"
        >
            <GiftContact />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default GiftAFriend;
