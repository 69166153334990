import { FaAngry, FaHeartbeat, FaBriefcase, FaChild, FaWineBottle, FaBrain, FaSadTear, FaUtensils, FaSpider, FaSync, FaUser, FaHeart, FaVenus, FaBed, FaBalanceScale, FaLeaf, FaBaby, FaTint} from 'react-icons/fa';
import angerManagementImage from '../assets/anger_management.jpg';
import anxietyImage from '../assets/anxiety.jpg';
import careerMotivationImage from '../assets/career_motivation.jpg';
import childRelatedImage from '../assets/child_problems.jpg';
import deaddictionImage from '../assets/deaddiction.jpg';
import dementiaImage from '../assets/dementia.jpg';
import depressionImage from '../assets/depression.jpg';
import eatingDisorderImage from '../assets/eating_disorders.jpg';
import phobiaImage from '../assets/phobias.jpg';
import ocdImage from '../assets/ocd.jpg';
import personalityDevelopmentImage from '../assets/personality_development.jpg';
import relationshipIssuesImage from '../assets/relationship_issues.jpg';
import sexProblemImage from '../assets/sex_problems.jpg';
import sleepProblemsImage from '../assets/sleep_problems.jpg';
import stressManagementImage from '../assets/stress_management.jpg';
import Teacher from '../assets/Teacher.jpg';
import ParentSession from '../assets/parent_session.jpg';
import StudentSession from '../assets/student_session.jpg';
import Inperson from '../assets/in-person_consult.jpg';
import OnlineConsult from '../assets/online_consult.jpg';
import SupportGroup from '../assets/support_group.jpg';
import hormonalChangesImage from '../assets/hormonalChangesImage.jpg';
import motherhoodParentingImage from '../assets/motherhoodParentingImage.jpg';
import menstrual from '../assets/menstrual.jpg';
import menopausal from '../assets/menopausal.jpg';
import postpartum from '../assets/postpartum.jpg';
import perinatal from '../assets/perinatal.jpg';

export const services = [
  {
    id: 'anger-management',
    title: 'Anger Management',
    image: angerManagementImage,
    description: 'Our anger management services help individuals develop healthy coping mechanisms and strategies to better manage their emotions.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Manage Your Anger',
    icon: FaAngry
  },
  {
    id: 'anxiety',
    title: 'Anxiety',
    image: anxietyImage,
    description: 'We provide evidence-based treatments to help individuals overcome anxiety disorders and regain control of their lives.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Overcome Anxiety',
    icon: FaHeartbeat
  },
  {
    id: 'career-motivation',
    title: 'Career & Motivation',
    image: careerMotivationImage,
    description: 'Our career and motivation services assist individuals in finding purpose, setting goals, and developing the skills needed to achieve their professional aspirations.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Boost Your Career',
    icon: FaBriefcase
  },
  {
    id: 'child-related-problems',
    title: 'Child Related Problems',
    image: childRelatedImage,
    description: 'We offer specialized services to help children and families address a variety of challenges, including behavioral issues, emotional distress, and developmental concerns.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Support Your Child',
    icon: FaChild
  },
  {
    id: 'deaddiction',
    title: 'Deaddiction',
    image: deaddictionImage,
    description: 'Our deaddiction services provide comprehensive support and treatment for individuals struggling with substance abuse, helping them achieve long-term recovery.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Break Free from Addiction',
    icon: FaWineBottle
  },
  {
    id: 'dementia-forgetfulness',
    title: 'Dementia & Forgetfulness',
    image: dementiaImage,
    description: 'We offer personalized care and interventions for individuals experiencing cognitive decline, memory loss, and other dementia-related symptoms.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Support Memory Health',
    icon: FaBrain
  },
  {
    id: 'depression',
    title: 'Depression',
    image: depressionImage,
    description: 'Our depression treatment services combine evidence-based therapies and personalized support to help individuals manage their symptoms and improve their overall mental well-being.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Combat Depression',
    icon: FaSadTear
  },
  {
    id: 'eating-disorders',
    title: 'Eating Disorders',
    image: eatingDisorderImage,
    description: 'We provide specialized care and treatment for individuals dealing with eating disorders, including anorexia, bulimia, and binge eating disorder.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Improve Your Relationship with Food',
    icon: FaUtensils
  },
  {
    id: 'fear-phobias',
    title: 'Fear & Phobias',
    image: phobiaImage,
    description: 'Our services help individuals overcome various fears and phobias through exposure therapy and other effective techniques, enabling them to live more confident and fulfilling lives.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Conquer Your Fears',
    icon: FaSpider
  },
  {
    id: 'ocd',
    title: 'Obsessions & Compulsions (OCD)',
    image: ocdImage,
    description: 'We provide comprehensive treatment for individuals struggling with obsessive-compulsive disorder, helping them manage intrusive thoughts and develop healthy coping mechanisms.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Manage OCD',
    icon: FaSync
  },
  {
    id: 'personality-development',
    title: 'Personality Development',
    image: personalityDevelopmentImage,
    description: 'Our personality development services focus on helping individuals explore their inner selves, build self-awareness, and cultivate positive personality traits.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Enhance Your Personality',
    icon: FaUser
  },
  {
    id: 'relationship-issues',
    title: 'Relationship Issues',
    image: relationshipIssuesImage,
    description: 'We offer counseling and therapy services to help individuals and couples navigate relationship challenges, improve communication, and strengthen emotional connections.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Strengthen Relationships',
    icon: FaHeart
  },
  {
    id: 'sex-problems',
    title: 'Sexual Problems',
    image: sexProblemImage,
    description: 'Our specialized services address a range of sexual health concerns, providing discreet and compassionate support to help individuals and couples overcome sexual difficulties.',
    provider: 'Dr. Madhu Vamsi',
    category: 'sexual',
    ctaText: 'Enhance Sexual Health',
    icon: FaVenus
  },
  {
    id: 'sleep-issues',
    title: 'Sleep Issues',
    image: sleepProblemsImage,
    description: 'We help individuals develop healthy sleep habits and overcome various sleep-related problems, such as insomnia, sleep apnea, and circadian rhythm disorders.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Improve Sleep Quality',
    icon: FaBed
  },
  {
    id: 'stress-management',
    title: 'Stress Management',
    image: stressManagementImage,
    description: 'Our stress management services equip individuals with effective techniques and strategies to better manage their stress levels, improve their overall well-being, and enhance their quality of life.',
    provider: 'Dr. Madhu Vamsi',
    category: 'mental',
    ctaText: 'Reduce Stress',
    icon: FaBalanceScale
  },
  {
    id: 'hormonal-changes',
    title: 'Hormonal Changes',
    image: hormonalChangesImage,
    description: 'Our services focus on addressing the mental health challenges associated with hormonal changes, providing support and guidance for managing emotional well-being during these transitions.',
    provider: 'Dr. Madhu Vamsi',
    category: 'women',
    ctaText: 'Understand Changes',
    icon: FaSync
  },
  
  {
    id: 'motherhood-parenting',
    title: 'Motherhood and Parenting',
    image: motherhoodParentingImage,
    description: 'We offer specialized support to mothers, helping them navigate the emotional and mental challenges of parenting while promoting overall mental wellness.',
    provider: 'Dr. Madhu Vamsi',
    category: 'women',
    ctaText: 'Support Motherhood',
    icon: FaChild
  },
  
  {
    id: 'menstrual',
    title: 'Menstrual',
    image: menstrual,
    description: 'Our services address the mental health challenges that can arise during menstrual cycles, offering effective coping strategies and emotional support.',
    provider: 'Dr. Madhu Vamsi',
    category: 'women',
    ctaText: 'Get Support',
    icon: FaTint
  },
  
  {
    id: 'menopausal',
    title: 'Menopausal',
    image: menopausal,
    description: 'We provide guidance and strategies to manage mental health during menopause, helping women navigate the emotional and psychological aspects of this transition.',
    provider: 'Dr. Madhu Vamsi',
    category: 'women',
    ctaText: 'Navigate Menopause',
    icon: FaLeaf
  },
  
  {
    id: 'postpartum',
    title: 'Postpartum',
    image: postpartum,
    description: 'Our postpartum mental health services offer emotional support and resources to new mothers, helping them manage mental health challenges during the postpartum period.',
    provider: 'Dr. Madhu Vamsi',
    category: 'women',
    ctaText: 'Get Postpartum Support',
    icon: FaBaby
  },
  
  {
    id: 'perinatal',
    title: 'Perinatal',
    image: perinatal,
    description: 'Our perinatal mental health services provide support during pregnancy and the early stages of motherhood, promoting emotional well-being throughout the perinatal period.',
    provider: 'Dr. Madhu Vamsi',
    category: 'women',
    ctaText: 'Support Pregnancy',
    icon: FaHeartbeat
  },  
];

export const categories = [
  { id: 'mental', name: 'Mental Health' },
  { id: 'sexual', name: 'Sexual Health' },
  { id: 'women', name: "Women's Health" }
];


export const helpItems = [
  { icon: '🧠', label: 'Depression' },
  { icon: '❤️‍🩹', label: 'Anxiety' },
  { icon: '🌙', label: 'Sleep disturbances' },
  { icon: '👥', label: 'Relationship issues' },
];


export const workplaceServices = [
    { title: "Job satisfaction", value: 10, color: "#FF5733", emoji: "💼" },
    { title: "Stress management", value: 10, color: "#33FFBD", emoji: "😌" }, 
    { title: "Communication skills", value: 10, color: "#3380FF", emoji: "🗣️" }, 
    { title: "Work-life balance", value: 10, color: "#FFC300", emoji: "⚖️" }, 
    { title: "Confidence building", value: 10, color: "#8C33FF", emoji: "💪" }, 
    { title: "Conflict management", value: 10, color: "#FF33E3", emoji: "🤝" }, 
    { title: "Team building", value: 10, color: "#33FF57", emoji: "🤼" }, 
    { title: "Relationship counseling", value: 10, color: "#FF3367", emoji: "❤️" },
    { title: "Financial counseling", value: 10, color: "#33CFFF", emoji: "💰" }, 
    { title: "Career guidance", value: 10, color: "#B833FF", emoji: "🎯" }, 
  ];
  

export const workplacetreatments = [
  { name: '1 on 1 online consultations', image: require('../assets/online_consult.jpg') },
  { name: 'Online support groups', image: require('../assets/support_group.jpg') },
  { name: 'Awareness sessions', image: require('../assets/awareness.jpg') },
  { name: 'Consultations for families', image: require('../assets/family_consult.jpg') },
  { name: 'In-person consultations', image: require('../assets/in-person_consult.jpg') },
  { name: 'Therapy', image: require('../assets/therapy.jpg') },
];

export const studentServices = [
  { title: "Mental health", value: 10, color: "#019b98", emoji: "🧠" },
  { title: "Emotional well-being", value: 10, color: "#55ccc9", emoji: "😊" },
  { title: "Social skills", value: 10, color: "#c1ffff", emoji: "🤝" },
  { title: "Academic performance", value: 10, color: "#dd0025", emoji: "📚" },
  { title: "Behavior management", value: 10, color: "#ffbfab", emoji: "🚦" },
  { title: "Attention deficit", value: 10, color: "#014e60", emoji: "🎯" },
  { title: "Learning disabilities", value: 10, color: "#3f7a8d", emoji: "💡" },
  { title: "Autism spectrum disorder", value: 10, color: "#fbfbfb", emoji: "🧩" },
  { title: "Speech and language delay", value: 10, color: "#f1f1f1", emoji: "🗣️" },
  { title: "Physical development", value: 10, color: "#c8c8c8", emoji: "🏃" },
];

export const studentTreatments = [
  { name: 'Teacher awareness sessions', image: Teacher },
  { name: 'Parent awareness sessions', image: ParentSession },
  { name: 'Sessions for students', image: StudentSession },
  { name: '1 on 1 online consultations', image: OnlineConsult },
  { name: 'In-person consultations', image: Inperson },
  { name: 'Online support groups', image: SupportGroup },
];
