import { motion } from "framer-motion";

const services = [
  { name: 'Mental Health', icon: '🧠', description: 'Comprehensive mental health services including therapy, counseling, and psychiatric care.' },
  { name: 'Sexual Health', icon: '❤️', description: 'Confidential sexual health services, education, and support for all individuals.' },
  { name: 'Speech Therapy', icon: '🗣️', description: 'Expert speech and language therapy for children and adults.' },
  { name: "Women's Health", icon: '♀️', description: 'Specialized care addressing the unique health needs of women at all life stages.' },
  { name: 'LGBTQIA+ Services', icon: '🏳️‍🌈', description: 'Inclusive and affirming healthcare services for the LGBTQ+ community.' },
];

export default function Services() {
  return (
    <div className="bg-gradient-to-r from-purple-500 to-pink-500 text-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-xl sm:rounded-2xl md:rounded-3xl">
      <div className="max-w-5xl mx-auto py-6 sm:py-8 md:py-10">
        <motion.h2
          className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-8 md:mb-10 text-center"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          CONDITIONS WE AID
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <motion.div
              key={service.name}
              className="bg-white bg-opacity-20 p-4 sm:p-6 md:p-8 text-center rounded-lg"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: index * 0.2 }}
            >
              <div className="text-4xl sm:text-5xl md:text-6xl mb-3 sm:mb-4 md:mb-5">{service.icon}</div>
              <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 sm:mb-3 md:mb-4">{service.name}</h3>
              <p className="text-base sm:text-lg md:text-xl">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
