import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function ServiceTemplate({ service }) {
  const Icon = service.icon;

  return (
    <motion.div
      className="bg-white rounded-lg shadow-md overflow-hidden"
      whileHover={{ y: -5 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="relative">
        <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
        <div className="absolute top-0 left-0 bg-primary-100 text-white px-3 py-1 rounded-br-lg">
          {service.category}
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center mb-4">
          <Icon className="text-primary-100 text-3xl mr-3" />
          <h3 className="text-2xl font-bold">{service.title}</h3>
        </div>
        <p className="text-gray-600 mb-4 line-clamp-3">{service.description}</p>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">Provider: {service.provider}</span>
          <Link
            to={`/service/${service.id}`}
            className="bg-primary-100 text-white px-4 py-2 rounded-full hover:bg-primary-200 transition duration-300 center"
          >
            {service.ctaText || 'Learn More'}
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default ServiceTemplate;