import React from 'react';
import { motion } from 'framer-motion';
import TemplateService from './TemplateService';
import medicationsImage from '../../assets/medication.jpg';
import GiftContact from '../giftAFriend/GiftContact';
import PrivacyNote from '../diagnostics/PrivacyNote';
import { medicationServices } from '../../data/data';


const Medications = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="relative w-full h-96 flex items-center justify-center mb-8 overflow-hidden rounded-3xl">
        <motion.img
          src={medicationsImage}
          alt="Medications Hero"
          className="w-full h-full object-cover"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white text-center">Medication Services</h1>
        </div>
      </div>

      {/* Description */}
      <p className="text-lg text-text-200 mb-12 text-center max-w-3xl mx-auto">
        We offer a variety of medication options to suit your individual needs, ensuring effective treatment and management of your condition.
      </p>

      {/* Service Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {medicationServices.map((service, index) => (
          <TemplateService
            key={index}
            title={service.title}
            image={service.image}
            bulletPoints={service.bulletPoints}
          />
        ))}
      </div>
      <GiftContact />
      <PrivacyNote />
    </div>
  );
};

export default Medications;
