import React from 'react';
import { motion } from 'framer-motion';

function CallToAction({ variants }) {
  const message = "Hello%20doctor,%20I%20came%20across%20your%20initiative%20and%20would%20like%20to%20discuss%20how%20we%20can%20bring%20Dopamine%20For%20Thought%20to%20our%20organization.";
  const phoneNumber = "+919700168944"; // Replace with your full phone number in international format
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

  return (
    <motion.section
      variants={variants}
      className="p-8 rounded-lg text-center"
    >
      <h3 className="text-5xl font-semibold mb-4">
        Ready to bring Dopamine For Thought to your organization?
      </h3>
      <a 
        href={whatsappLink} target="_blank" rel="noopener noreferrer"
        className="bg-accent-100 text-white font-bold my-10 py-3 px-6 rounded-lg text-xl hover:bg-opacity-90 transition-colors"
      >
        Talk to Us
      </a>
    </motion.section>
  );
}

export default CallToAction;
