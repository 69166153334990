import React from 'react';
import { motion } from 'framer-motion';

function AssessmentCTA() {
  return (
    <section className="bg-primary-200 py-16 text-center relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
        className="relative z-10"
      >
        <h2 className="text-3xl font-bold mb-4">Not sure where to begin?</h2>
        <p className="mb-8 text-lg max-w-2xl mx-auto">Take our assessment to get personalized recommendations tailored to your needs.</p>
        <a href="https://tally.so/r/wa0kBv" target="_blank" rel="noopener noreferrer">
        <motion.button
          className="bg-accent-100 text-white px-8 py-4 rounded-full font-bold text-lg shadow-lg"
          whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(221, 0, 37, 0.5)" }}
          whileTap={{ scale: 0.95 }}
        >
          Start Your Assessment
        </motion.button>
      </a>
      </motion.div>
      <motion.div 
        className="absolute inset-0 bg-primary-100 opacity-10"
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{ duration: 60, repeat: Infinity, ease: "linear" }}
      />
    </section>
  );
}

export default AssessmentCTA;