import React from 'react';
import { motion } from 'framer-motion';
import TemplateService from './TemplateService';
import counsellingImage from '../../assets/counselling-services.png'; // Hero image
import { counsellingServices } from '../../data/data';
import GiftContact from '../giftAFriend/GiftContact';
import PrivacyNote from '../diagnostics/PrivacyNote';

const Counselling = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="relative w-full h-96 flex items-center justify-center overflow-hidden rounded-3xl mb-8">
        <motion.img
          src={counsellingImage}
          alt="Counselling Hero"
          className="w-full h-full object-cover"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white text-center">Counselling Services</h1>
        </div>
      </div>

      {/* Description */}
      <p className="text-lg text-text-200 mb-12 text-center max-w-3xl mx-auto">
        Our comprehensive counselling services cater to a wide range of needs, providing professional support for individuals, couples, and families.
      </p>

      {/* Service Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {counsellingServices.map((service, index) => (
          <TemplateService
            key={index}
            title={service.title}
            image={service.image}
            bulletPoints={service.bulletPoints}
          />
        ))}
      </div>
      <GiftContact />
      <PrivacyNote />
    </div>
  );
};

export default Counselling;
