import React from 'react';
import { motion } from 'framer-motion';
import TemplateService from './TemplateService';
import GiftContact from '../giftAFriend/GiftContact';
import PrivacyNote from '../diagnostics/PrivacyNote';
import { labServices } from '../../data/data';
import labImage from '../../assets/lab.jpg';


const Lab = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="relative w-full h-96 flex items-center justify-center mb-8 overflow-hidden rounded-3xl">
        <motion.img
          src={labImage}
          alt="Lab Hero"
          className="w-full h-full object-cover"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white text-center">Laboratory Services</h1>
        </div>
      </div>

      {/* Description */}
      <p className="text-lg text-text-200 mb-12 text-center max-w-3xl mx-auto">
        Our state-of-the-art laboratory offers a wide range of tests with a focus on accuracy, affordability, and convenience.
      </p>

      {/* Service Cards */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
        {labServices.map((service, index) => (
          <TemplateService
            key={index}
            title={service.title}
            image={service.image}
            bulletPoints={service.bulletPoints}
          />
        ))}
      </div>
      <GiftContact />
      <PrivacyNote />
    </div>
  );
};

export default Lab;
