import React from 'react';
import { motion } from 'framer-motion';

// Import images
import image1 from '../../assets/workplace_pamphlet.png';
import image2 from '../../assets/student_pamphlet.png';

function BrochureDownload({ variants, name }) {
  // Determine which image to use based on the name prop
  console.log(name);
  const imageSrc = name === "Workplace Wellness" ? image1 : image2;
  const imageName = name === "Workplace Wellness" ? "workplace_brochure.png" : "student_brochure.png"; // Set the download file name

  return (
    <motion.section
      variants={variants}
      className="bg-bg-200 p-8 rounded-lg shadow-lg"
    >
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 mb-4 md:mb-0">
          <h3 className="text-2xl font-semibold mb-4">Download Our Brochure</h3>
          <p className="mb-4">
            Get comprehensive information about our {name} programs and how they can benefit your organization.
          </p>
          <a
            href={imageSrc}
            download={imageName}
            className="bg-primary-100 text-white font-bold py-2 px-4 rounded hover:bg-opacity-90 transition-colors"
          >
            Download Brochure (Image)
          </a>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <motion.img
            src={imageSrc}
            alt={name}
            className="w-2/3 h-auto rounded-lg shadow-md"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          />
        </div>
      </div>
    </motion.section>
  );
}

export default BrochureDownload;
