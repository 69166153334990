import React from 'react';
import { motion } from 'framer-motion';

const BusinessHero = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="flex flex-col items-center text-center py-20 bg-gradient-to-r from-primary-200 to-primary-300 rounded-b-3xl"
    >
      {/* Image Section */}
      {/* We can add business-related images here if needed */}
      
      <div className="mt-8">
        <h1 className="text-4xl font-heading text-text-100">
          Elevate Your <span className="text-accent-100 italic">Business</span> with Wellness
        </h1>
        <p className="text-lg mt-4 text-text-200">
          Enhance productivity and well-being in your workplace and educational institutions.
        </p>
      </div>
      
      <div className="mt-12">
        <motion.button
          className="text-7xl text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition-colors cursor-default"
          whileHover={{ scale: 1.2, rotate: 15 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.span
            whileHover={{ scale: 1.5, rotate: 360 }}
            transition={{ duration: 0.6, repeat: Infinity, repeatType: 'reverse' }}
          >
            🌟
          </motion.span>
        </motion.button>
      </div>
    </motion.div>
  );
};

export default BusinessHero;
